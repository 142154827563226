import "../scss/app.scss";

// Import jQuery (and extensions for working with MVC forms)
import $ from 'jquery';

import { Tooltip, Toast, Popover } from 'bootstrap';

import Prism from 'prismjs';
Prism.highlightAll();

// Import Flickity Carousel
var Flickity = require('flickity');
require('flickity-imagesloaded');

const flickityOptions = {
    "Default": {
        "cellAlign": "left",
        "wrapAround": true,
        "autoPlay": false,
        "pageDots": true,
        "imagesLoaded": true,
        "resize": true
    },
    "Grid_Quotes": {
        "cellSelector": "blockquote",
        "autoPlay": 5000
    }
};

let updateQueryParams = function (input) {
    let name = $(input).attr("name");
    let value = $(input).val();
    if ('URLSearchParams' in window) {
        // Primary method using URLSearchParams JS API to add or update page query param
        let url = new URL(window.location);
        if (value && value.length) {
            url.searchParams.set(name, value);
        } else {
            url.searchParams.delete(name);
        }
        // Ensure page is reset to one (as new filters have been applied)
        if (url.searchParams.has("page")) {
            url.searchParams.set("page", 1);
        }
        // Reload with new query params in place
        window.location.search = url.searchParams.toString();
    } else {
        // Fallback method which blindly appends a name=value string to the query params or removes them entirely
        if (value && value.length) {
            window.location.search += (window.location.search.length > 0)
                ? "&" + name + "=" + value
                : "?" + name + "=" + value;
            // Ensure page is reset to one (as new filters have been applied)
            window.location.search += "&page=1";
        } else {
            window.location.search = "";
        }
    }
}

window.addEventListener("DOMContentLoaded", (event) => {

    // Update all carousels on page load
    document.querySelectorAll(".main-carousel").forEach((ele, key) => {
        try {
            let optionsKey = ele.attributes.getNamedItem("data-carousel-key").value;
            let defaultOpts = flickityOptions["Default"];
            let instanceOpts = (optionsKey && flickityOptions.hasOwnProperty(optionsKey))
                ? flickityOptions[optionsKey]
                : {};
            let opts = { ...defaultOpts, ...instanceOpts };
            let carousel = new Flickity(ele, opts);
        } catch (error) {
            console.error(error);
        }
    });

    // Update URL Query Params on Filter via Select and Input 'change' and 'input' events
    document.querySelectorAll("select[data-filter], input[data-filter]").forEach((item) => {
        item.addEventListener(
            "change",
            function (event) {
                event.preventDefault();
                updateQueryParams(this);
            }
        )
    });

});